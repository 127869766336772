<template>
  <div id="app"
    class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
    <HeadNav selectedLanguage="en" />

    <div id="page" class="site grid-container container hfeed">
      <div id="content" class="site-content">
        <div id="primary" class="content-area">
          <main id="maincontent" class="site-main bro-bottom">
            <section id="section-1" class="section-content">
              <BreadCrumb :breadcrumbs="breadcrumbs" />
              <div class="lazy-picture-container writer-banner full-width">
                <picture><img width="auto" height="auto" alt="Download All WhatsApp Mods"
                    src="@/assets/Download All WhatsApp Mods.webp"></picture>
              </div>

              <h2 class="intro-title blog bor-bottom">
                WhatsApp Mods APK Download | Latest Official & Anti-Ban Updates 2025
              </h2>

              <p class="writter-content">
                Download all <strong>WhatsApp Mods</strong> with the latest <strong>official and anti-ban</strong>
                versions in 2025. Enjoy advanced features, enhanced privacy,
                and ultimate customization for a better messaging experience. Popular mods include
                <strong>FMWhatsApp,</strong> <a href="https://www.gbwhatsapp.chat/" class="jump-url">GBWhatsApp</a>,
                <strong>YoWhatsApp,JTWhatsApp,WhatsApp Plus</strong>
                and more, often with anti-ban protection and the latest updates.
              </p>

              <div class="gbwhatsapp-sample">
                <div class="mods-container1">
                  <div class="mod-item1" v-for="(mod, index) in modsGallery" :key="index" @click="onlink(mod.link)">
                    <img :src="mod.image" :alt="mod.name" class="mod-icon1">
                    <span class="mod-name1">{{ mod.name }}</span>
                  </div>
                </div>
              </div>

              <h3 class="intro-title blog">
                Why Choose WhatsApp Mods?
              </h3>

              <p class="writter-content">
                ✅ Hide online & last seen status <br>
                ✅ Download & send high-quality images/videos<br>
                ✅ Use multiple accounts on the same device<br>
                ✅ Custom themes & enhanced UI experience<br>
                ✅ Anti-ban protection for safe usage<br>
                By choosing <strong>WhatsApp Mods</strong>, you can enjoy a more personalized, feature-rich, and
                flexible messaging experience! it offers enhanced privacy features, customization options, and advanced
                functionalities that the official app doesn’t provide.
              </p>

              <h2 class="intro-title blog">
                All WhatsApp APK Download NOW
              </h2>
              <p class="writter-content">
                Get <strong>all WhatsApp downloads</strong>, including the latest <strong>WhatsApp Mods</strong> like
                FMWhatsApp, GBWhatsApp, YoWhatsApp, and more.
              </p>

              <div class="download-mod">
                <div class="mod-version" v-for="(item, index) in modVersions" :key="index">
                  <img :src="item.icon" :alt="item.versionName" class="mod-icon">
                  <div class="mod-info">
                    <a href="https://www.gbwhatsapp.chat/downloadpage/" v-if="index==0" class="jump-url"><h3>{{ item.versionName }} | Size {{ item.size }}</h3></a>
                    <h3 v-else>{{ item.versionName }} | Size {{ item.size }}</h3>
                  </div>
                  <button class="download-btn" @click="downloadfrom('bigbtn')">
                    Download
                  </button>
                </div>
              </div>

              <h2 class="blog">
                How to Download & Install WhatsApp Mod?
              </h2>
              <ol>
                <li>
                  Click the <strong>Download</strong> button below to get the <strong>WhatsApp Mod APK Latest
                    Version.</strong>
                </li>
                <li> Enable "Install from Unknown Sources" in your device settings.</li>
                <li>Install the APK and open the app.</li>
                <li> Verify your number and enjoy premium WhatsApp features!</li>
              </ol>

              <h2 class="blog">
                How do I update the mod WhatsApp applications?
              </h2>
              <p class="writter-content">
                Updating <strong>WhatsApp Mod</strong> applications is different from updating the official WhatsApp.
                Since these are third-party apps, they don’t update automatically via the Play Store. Follow these steps
                to <strong>safely update</strong> your modded WhatsApp version:
              </p>

              <h2 class="blog">
                Steps to Update WhatsApp Mod APK
              </h2>
              <ol>
                <li><strong>Backup Your Chats</strong></li>
                <ul>
                  <li>Open your WhatsApp Mod app ( <strong>FMWhatsApp, GBWhatsApp, YoWhatsApp, etc.</strong>).</li>
                  <li>Go to <strong>Settings > Chats > Chat</strong> Backup and back up your messages to local storage.
                  </li>
                </ul>
                <li><strong>Download the Latest Version</strong></li>
                <ul>
                  <li>Visit a trusted website that provides the <strong>latest WhatsApp Mod APK (Anti-Ban
                      version).</strong></li>
                  <li>Look for the updated version of <strong>FMWhatsApp, GBWhatsApp, YoWhatsApp, or WhatsApp
                      Plus.</strong></li>
                  <li>If you're looking for How to Download GBWhatsApp APK, search for the most recent version from a
                    trusted source. Ensure it’s the <strong>Anti-Ban</strong> version for better security.</li>
                </ul>
                <li><strong>Uninstall the Old Version (Optional)</strong></li>
                <ul>
                  <li>If the new version is compatible with the old one, you can install it directly.</li>
                  <li>If you face installation issues, uninstall the old version but <strong>DO NOT delete the
                      "WhatsApp" folder</strong> to keep your data.</li>
                </ul>
                <li><strong>Install the New APK</strong></li>
                <ul>
                  <li>Enable <strong>"Install from Unknown Sources"</strong> in your phone’s settings.</li>
                  <li>Locate the downloaded APK file and install it.</li>
                </ul>
                <li><strong>Restore Your Chats</strong></li>
                <ul>
                  <li>Open the app, verify your phone number, and restore your backup during setup.</li>
                </ul>
              </ol>

              <RecentPost />

            </section>
          </main>
        </div>
      </div>
    </div>



    <Footer />

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn" @click="gotodownload()">
      Download GBWhatsapp APK
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/blog/pc.scss';
import doc from '@/utlis/blogList.js';
import HeadNav from '@/components/HeadNav.vue';
import Footer from '@/components/Footer.vue';
import BreadCrumb from '@/components/BreadCrumb.vue';
import RecentPost from '@/components/RecentPost.vue';
export default {
  name: 'Home',
  components: {
    HeadNav,
    Footer,
    BreadCrumb,
    RecentPost
  },
  data() {
    return {
      pageName: 'home',
      apk: null,
      doc: doc,
      modsGallery: [
        {
          image: require('@/assets/logo.webp'),
          name: "GBWhatsApp",
          link: "https://www.gbwhatsapp.chat/"
        },
        {
          image: require('@/assets/logo1.webp'),
          name: "FM WhatsApp",
          link: "https://www.gbwhatsapp.chat/fmwhatsapp-apk-download/"
        },
        {
          image: require('@/assets/logo2.webp'),
          name: "YOWhatsApp",
          link: "https://www.gbwhatsapp.chat/yowhatsapp-apk-download/"
        },
        {
          image: require('@/assets/logo3.webp'),
          name: "WhatsApp Plus",
          link: "https://www.gbwhatsapp.chat/whatsapp-plus-apk-download/"
        },
        {
          image: require('@/assets/logo4.webp'),
          name: "OGWhatsApp",
          link: "https://www.gbwhatsapp.chat/ogwhatsapp-apk-download/"
        },
        {
          image: require('@/assets/logo5.webp'),
          name: "JTWhatsApp",
          link: "https://www.gbwhatsapp.chat/jtwhatsapp-apk-download/"
        }
      ],
      modVersions: [
        {
          icon: require('@/assets/logo.webp'),
          versionName: 'GB WhatsApp Version 18.30',
          size: '72MB',
        },
        {
          icon: require('@/assets/logo1.webp'),
          versionName: 'FM WhatsApp APK v10.10 ',
          size: '76MB',
        },
        {
          icon: require('@/assets/logo2.webp'),
          versionName: 'YoWhatsApp Apk v10.10',
          size: '80MB',
        },
        {
          icon: require('@/assets/logo3.webp'),
          versionName: 'WhatsApp Plus APK v18.30',
          size: '80MB',
        },
        {
          icon: require('@/assets/logo4.webp'),
          versionName: 'OG WhatsApp APK v18.30',
          size: '80MB',
        },
        {
          icon: require('@/assets/logo5.webp'),
          versionName: 'JTWhatsApp APK v10.2',
          size: '75MB',
        },
      ]
    };
  },
  mounted() {
    document.documentElement.lang = 'en';
    this.download()
  },
  computed: {
    reverseDoc() {
      return this.doc.bloglist.slice().reverse();
    },
    breadcrumbs() {
      return this.$route.matched.reduce((acc, route) => {
        const crumbs = route.meta.breadcrumb || [];
        return acc.concat(crumbs);
      }, []);
    },
  },
  methods: {
    gotodownload() {
      // const params = new URLSearchParams(window.location.href.search)
      let params = window.location.search;
      window.location.href = '/downloadpage' + params;
    },
    // godownload(){
    //   window.location.href = '/downloadpage' + this.apk;
    // },
    onlink(link) {
      window.location.href = link;
    },
    jump(url) {
      window.location.href = url;
    },
    downloadfrom(reason) {
      this.$logEvent(`download_from_${reason}`, 'download');
      this.$global.download(this.apk);
      // window.location.href = `https://gbapp.pro?apps=gb&domain=gbwhatsapp.chat&appName=GB_gbwhatsapp.chat`;
    },
    download() {
      this.$server
        .getOfficalApk({
          domain: 'gbwhatsapp.chat',
          appName: 'GB_gbwhatsapp.chat',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.apk = res.data.apk;
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }

        });
    },
  },
};
</script>

<style lang="scss" scoped>
.green-box {
  span {
    padding: 5px 8px;
    background-color: #5cc5bf;
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    color: #222;
  }

  margin-bottom: 10px;
}
</style>
